.presentation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  align-items: center;
}

.header-text {
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  color: #1c48b0;
  font-family: 'Oswald', sans-serif;
}

.title {
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.subtitle {
  text-align: center;
  color: #000;
  text-align: center;
  letter-spacing: -0.01px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  margin: 10px 0;
}

.cf-pricing-plan-check-icon {
  width: 24px;
  height: 28px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 2em 0;
  padding: 0;
}

.list-element-container {
  display: flex;
  justify-items: center;
  gap: 10px;
  align-items: center;
}

.list-element {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.input {
  padding: 8px 16px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.27);
  font-family: inherit;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.27);
}

.form {
  display: flex;
  gap: 1em;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox {
  margin: 0.2em 0.6em 0 0;
}

.checkbox-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51);
  font-family: Arial, Helvetica, sans-serif;
}

.button {
  display: inline-block;
  width: 100%;
  border-radius: 20px;
  background-color: #1c48af;
  background-image: linear-gradient(67deg, #1c48af, #356ae6);
  box-shadow: rgba(0, 47, 255, 0.23) -1px 8px 5px -3px;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  border: 0;
  padding: 9px 15px;
  color: #fff;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.hero-container {
  margin: 3em 0;
  width: 100%;
  padding: 2em 5em;
  border-radius: 12px;
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
}

.hero-img {
  border-radius: 100px;
  object-fit: cover;
  background: hsla(222, 100%, 61%, 1);

  background: linear-gradient(45deg, hsla(222, 100%, 61%, 1) 0%, hsla(235, 83%, 21%, 1) 100%);

  background: -moz-linear-gradient(45deg, hsla(222, 100%, 61%, 1) 0%, hsla(235, 83%, 21%, 1) 100%);

  background: -webkit-linear-gradient(
    45deg,
    hsla(222, 100%, 61%, 1) 0%,
    hsla(235, 83%, 21%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#3874FF", endColorstr="#091162", GradientType=1 );
}

.hero-title {
  max-width: none;
  color: #1c48af;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.hero-text {
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.strong {
  font-weight: 900;
}

@media (max-width: 1000px) {
  .presentation-container {
    padding: 0 3em;
  }

  .hero-container {
    flex-direction: column;
  }

  .hero-title {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .presentation-container {
    gap: 1em;
    padding: 0;
  }

  .header-text {
    font-size: 18px;
  }

  .title {
    font-size: 15px;
    line-height: 125%;
  }

  .subtitle {
    font-size: 13px;
    line-height: 20px;
  }

  .list {
    gap: 1em;
    margin: 0;
  }

  .list-element {
    font-size: 13px;
    line-height: 1.5em;
  }

  .form {
    flex-direction: column;
    gap: 0.4em;
  }

  .hero-container {
    padding: 1em 2em;
    gap: 0.5em;
    width: 95%;
  }
}
