.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.content-container {
  max-width: 980px;
  margin: 0 0.5em;
}

@media (max-width: 700px) {
  .app-container {
    margin: 1em 0;
  }
}
